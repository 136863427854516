import axios from 'axios';
import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import Icon from '../../../components/Icon.jsx';
import JoinSmzInstruction from '../../../components/JoinSmzInstruction.jsx';
import ListAbsoluteMain from '../../../components/ListAbsoluteMain.jsx';

import getHeaders from '../../../functions/getHeaders';
import { inputValidate } from '../../../functions/inputValidate';
import setNotification from '../../../functions/setNotification';

class JoinStatus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: localStorage.getItem('crmJoinStatusCurrentPage') || 'start',
            currentSmzFormType: localStorage.getItem('crmJoinCurrentSmzFormType') || 'phone',
            // currentPage: 'smz-numbersInfo',
            fnsPhone: localStorage.getItem('crmJoinFnsPhone'),
        };

        this.getHandlerButton = this.getHandlerButton.bind(this);
        this.renderStatusPage = this.renderStatusPage.bind(this);

        this.handlerSmz = this.handlerSmz.bind(this);
        this.handlerIp = this.handlerIp.bind(this);
        this.renderSmzFormField = this.renderSmzFormField.bind(this);
    }

    statuses = {
        FL: {
            title: 'Я физ. лицо (ГПХ)',
            description: 'Вы будете работать по договору гражданско-правового характера',
        },
        'FL-A': {
            title: 'Я физ. лицо (Агентский договор)',
            description: 'Вы будете работать по агентскому договору',
        },
        IP: {
            title: 'У меня ИП',
            description: 'У вас есть действующий<br/>статус индивидуального предпринимателя',
        },
        SMZ: {
            title: 'Я самозанятый',
            description: 'У вас есть действующий<br/>статус самозанятого',
        },
    };

    renderStatus({ key }) {
        const { setOrganizationStatus, currentOrganization, statusError } = this.props;
        const statusInfo = this.statuses[key];

        return (
            <label className={`join__status ${statusError ? '_error' : ''}`}>
                <input
                    type="checkbox"
                    className="join__statusInput"
                    checked={currentOrganization === key}
                    onChange={() => setOrganizationStatus({ status: key })}
                />
                <div className="join__statusContent _click">
                    <div className="join__statusTitle">{statusInfo?.title}</div>
                    <p
                        className="join__statusDescription"
                        dangerouslySetInnerHTML={{ __html: statusInfo?.description }}
                    ></p>
                    <div className="join__statusPoint _col" />
                </div>
            </label>
        );
    }

    getStatusPages(isAll) {
        const { currentPage } = this.state;
        const { joinScript } = this.props;
        const { executorOrganization } = joinScript;

        if (isAll) {
            if (executorOrganization === 'FL' || executorOrganization === 'FL-A') {
                return ['start'];
            }

            if (executorOrganization === 'IP') {
                return ['start', 'ip-inn'];
            }

            if (executorOrganization === 'SMZ') {
                return [
                    'start',
                    'smz-instruction',
                    'smz-access',
                    'smz-form',
                    'smz-check',
                    'smz-numbersInfo',
                ];
            }
        }

        return [{ key: currentPage }];
    }

    setCurrentPage({ currentPage }) {
        this.setState({ currentPage });

        localStorage.setItem('crmJoinStatusCurrentPage', currentPage);
    }

    handlerIp() {
        const { currentPage } = this.state;
        const {
            currentOrganization,
            handlerStatusError,
            fields,
            handlerErrors,
            getLink,
            handlerLoadingButton,
            handlerFns,
            setCurrentStep,
        } = this.props;

        if (currentPage === 'start') {
            if (!currentOrganization) {
                handlerStatusError({ statusError: 'error' });
            } else {
                this.setCurrentPage({ currentPage: 'ip-inn' });
            }
        } else {
            const inn = fields.inn?.value;

            if (!inputValidate({ name: 'inn', value: inn })) {
                handlerErrors({ errors: [{ name: 'inn', error: 'validation' }] });
            } else {
                const phone = fields.phone?.value;
                const link = getLink();

                handlerLoadingButton('status').then(() => {
                    const query = `link=${link}&phone=${phone}&fnsInn=${inn}`;

                    axios
                        .get(`${process.env.REACT_APP_API}/join?${query}`, {
                            headers: getHeaders(),
                        })
                        .then(
                            (res) => {
                                const { success, data } = res.data;

                                if (success) {
                                    const { fnsStatus, executorInfo } = data;

                                    handlerFns({ fnsStatus, executorInfo });

                                    setCurrentStep('anket');
                                } else {
                                    const { message } = data;

                                    if (message === 'Account not found') {
                                        setNotification({ notification: 'fns-account-not-found' });
                                    } else if (message === 'Inn already use') {
                                        setNotification({
                                            notification: 'inn-already-use',
                                        });
                                    }

                                    handlerErrors({
                                        errors: [{ name: 'inn', error: 'validation' }],
                                    });
                                }

                                handlerLoadingButton(null);
                            },
                            () => null,
                        );
                });
            }
        }
    }

    handlerSmz(smzKey) {
        const { currentPage } = this.state;
        const {
            currentOrganization,
            handlerStatusError,
            fields,
            handlerErrors,
            getLink,
            handlerLoadingButton,
            handlerFns,
            setCurrentStep,
        } = this.props;

        if (currentPage === 'start') {
            if (!currentOrganization) {
                handlerStatusError({ statusError: 'error' });
            } else {
                this.setCurrentPage({ currentPage: 'smz-access' });
            }
        } else if (currentPage === 'smz-access') {
            this.setCurrentPage({ currentPage: 'smz-form' });
        } else {
            const { currentSmzFormType } = this.state;
            const name = currentSmzFormType === 'phone' ? 'phone' : 'smz-inn';
            const value = fields[name]?.value;
            const reqKey = currentSmzFormType === 'phone' ? 'fnsPhone' : 'fnsInn';
            const code = localStorage.getItem('crmJoinCode');

            if (!inputValidate({ name: currentSmzFormType, value })) {
                handlerErrors({ errors: [{ name, error: 'validation' }] });
            } else {
                const phone = fields.phone?.value;
                const link = getLink();
                let loadingKey = currentPage === 'smz-form' ? 'status-form' : 'status-check';

                if (currentPage === 'smz-numbersInfo') {
                    loadingKey =
                        smzKey === 'updateNumber'
                            ? 'status-numbersInfo-updateNumber'
                            : 'status-numbersInfo-again';
                }

                handlerLoadingButton(loadingKey).then(() => {
                    let query = `link=${link}&code=${code}&phone=${phone}&${reqKey}=${value}`;

                    if (currentPage === 'smz-form') {
                        query += `&addToCompany=true`;
                    }

                    if (smzKey === 'updateNumber') {
                        query += `&updateNumber=true`;
                    }

                    axios
                        .get(`${process.env.REACT_APP_API}/join?${query}`, {
                            headers: getHeaders(),
                        })
                        .then(
                            (res) => {
                                const { success, data } = res.data;

                                if (success) {
                                    const {
                                        fnsStatus,
                                        executorInfo,
                                        numbersIsDifferent,
                                        fnsPhone,
                                    } = data;

                                    this.setState({ fnsPhone });

                                    if (fnsPhone) {
                                        localStorage.setItem('crmJoinFnsPhone', fnsPhone);
                                    }

                                    handlerFns({
                                        fnsStatus,
                                        executorInfo,
                                        ...(smzKey === 'updateNumber' ? { fnsPhone } : {}),
                                    });

                                    if (currentPage === 'smz-form') {
                                        this.setCurrentPage({ currentPage: 'smz-check' });
                                    } else if (numbersIsDifferent) {
                                        if (currentPage === 'smz-numbersInfo') {
                                            setNotification({
                                                notification: 'fns-phone-different',
                                            });
                                        }

                                        this.setCurrentPage({ currentPage: 'smz-numbersInfo' });
                                    } else {
                                        setCurrentStep('anket');
                                    }
                                } else {
                                    const { message, fnsStatus } = data;

                                    if (fnsStatus === null) {
                                        handlerFns({
                                            fnsStatus,
                                        });

                                        this.setCurrentPage({ currentPage: 'smz-form' });
                                    }

                                    if (message === 'Account already use') {
                                        setNotification({
                                            notification: 'fns-account-already-use',
                                        });
                                    } else if (
                                        message === 'Validation error' ||
                                        message === 'Account not found'
                                    ) {
                                        setNotification({ notification: 'fns-account-not-found' });
                                    } else if (message === 'Account not adding for company') {
                                        setNotification({ notification: 'fns-account-not-add' });
                                    } else if (message === 'Executor already create') {
                                        setNotification({ notification: 'fns-executor-phone-use' });
                                    } else if (message === 'Inn already use') {
                                        setNotification({
                                            notification: 'inn-already-use',
                                        });
                                    }

                                    handlerErrors({ errors: [{ name, error: 'validation' }] });
                                }

                                handlerLoadingButton(null);
                            },
                            () => null,
                        );
                });
            }
        }
    }

    getHandlerButton(key) {
        const { joinScript } = this.props;
        const { executorOrganization } = joinScript;

        if (executorOrganization === 'FL' || executorOrganization === 'FL-A') {
            return null;
        }

        if (executorOrganization === 'IP') {
            return this.handlerIp;
        }

        if (executorOrganization === 'SMZ') {
            return this.handlerSmz.bind(this, key);
        }

        return null;
    }

    pages = {
        start: {
            render() {
                const { renderButton, joinScript } = this.props;
                const { executorOrganization } = joinScript;

                return (
                    <>
                        <div className="join__blockTitle">
                            Выберите ваш
                            <br />
                            текущий статус
                        </div>
                        <div className="join__blockText">
                            Выберите статус, в котором
                            <br />
                            вы будете работать с заказчиком
                        </div>
                        <div className="join__statuses">
                            {this.renderStatus({ key: executorOrganization })}
                            {executorOrganization === 'SMZ' && (
                                <>
                                    <div className="join__status _link">
                                        <div
                                            className="join__statusContent _click"
                                            onClick={() => {
                                                this.setCurrentPage({
                                                    currentPage: 'smz-instruction',
                                                });
                                            }}
                                        >
                                            <div className="join__statusTitle">
                                                Я хочу стать самозанятым
                                            </div>
                                            <p className="join__statusDescription">
                                                Будет пройдена регистрация в качестве самозанятого.
                                                Займет 10 минут.
                                            </p>
                                            <i className="join__statusArrow">
                                                <Icon name="arrow-next" />
                                            </i>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        {renderButton({
                            name: 'status',
                            content: 'Продолжить',
                            onClick: this.getHandlerButton(),
                        })}
                    </>
                );
            },
        },
        'ip-inn': {
            render() {
                const { renderButton, renderField } = this.props;

                return (
                    <>
                        <div className="join__blockTitle">
                            Подключение
                            <br />в качестве ИП
                        </div>
                        <div className="join__blockText">
                            Для продолжения в качестве индивидуального предпринимателя введите ваш
                            ИНН
                        </div>
                        <div className="join__blockFields">{renderField({ name: 'inn' })}</div>
                        {renderButton({
                            name: 'status',
                            content: 'Продолжить',
                            onClick: this.getHandlerButton(),
                        })}
                    </>
                );
            },
        },
        'smz-instruction': {
            render() {
                const { renderButton } = this.props;

                return (
                    <>
                        <JoinSmzInstruction />
                        {renderButton({
                            name: 'status',
                            content: 'Всё получилось, продолжить',
                            onClick: () => {
                                this.setCurrentPage({ currentPage: 'smz-access' });
                            },
                            withArrow: false,
                        })}
                    </>
                );
            },
        },
        'smz-access': {
            render() {
                const { renderButton } = this.props;

                return (
                    <>
                        <div className="join__fnsLogo _col">
                            <img
                                src={require('../../../img/fns-logo.svg').default}
                                alt=""
                                className="join__fnsLogoIcon"
                            />
                        </div>
                        <div className="join__blockTitle">
                            Разрешите доступ
                            <br />к «Мой налог»
                        </div>
                        <div className="join__blockText _marginBottom">
                            Доступ нужен, чтобы мы могли декларировать ваш доход — считать
                            и&nbsp;оплачивать налоги за вас.
                        </div>
                        {renderButton({
                            name: 'status',
                            content: 'Разрешить доступ',
                            onClick: this.getHandlerButton(),
                            withArrow: false,
                        })}
                        <div className="join__fnsPartner _row">
                            <div className="join__fnsPartnerLogo">
                                <img
                                    src={require('../../../img/crm/joins/app-logo.svg').default}
                                    alt=""
                                    className="join__fnsPartnerLogoIcon"
                                />
                            </div>
                            <div className="join__fnsPartnerContent">
                                <b>LIVECARGO CRM —</b>
                                <br />
                                официальный партнёр ФНС
                            </div>
                        </div>
                    </>
                );
            },
        },
        'smz-form': {
            render() {
                const { currentSmzFormType } = this.state;
                const { renderButton, handlerErrors, setCurrentStep } = this.props;

                return (
                    <>
                        <div className="join__fnsLogo _col">
                            <img
                                src={require('../../../img/fns-logo.svg').default}
                                alt=""
                                className="join__fnsLogoIcon"
                            />
                        </div>
                        <div className="join__blockTitle">
                            Отправьте запрос
                            <br />в «Мой Налог»
                        </div>
                        <div className="join__blockText">
                            Укажите телефон, с которым вы
                            <br />
                            регистрировались на сайте ФНС,
                            <br />
                            либо ваш ИНН.
                        </div>
                        <div className="join__smzForm">
                            <div className="join__smzFormTabs _row">
                                {['phone', 'inn'].map((key) => {
                                    const content = key === 'phone' ? 'Номер телефона' : 'ИНН';

                                    return (
                                        <label className="join__smzFormTab" key={key}>
                                            <input
                                                type="checkbox"
                                                className="join__smzFormTabInput"
                                                checked={key === currentSmzFormType}
                                                onChange={() => {
                                                    this.setState({ currentSmzFormType: key });
                                                    localStorage.setItem(
                                                        'crmJoinCurrentSmzFormType',
                                                        key,
                                                    );
                                                }}
                                            />
                                            <div className="join__smzFormTabView">{content}</div>
                                        </label>
                                    );
                                })}
                            </div>
                            <ListAbsoluteMain
                                className="join__smzFormFields"
                                items={this.getSmzFormFields()}
                                renderItem={this.renderSmzFormField}
                                classNameItem="join__smzFormField"
                                prop="key"
                                paramsParent={{ width: true }}
                                styles={[]}
                                isNotParamsItem={true}
                                currentItemKey={currentSmzFormType}
                                allItems={['phone', 'inn']}
                                callback={({ isChangeLen }) => {
                                    if (isChangeLen) {
                                        handlerErrors({
                                            errors: [{ name: 'smz-phone' }, { name: 'smz-inn' }],
                                            action: 'delete',
                                        });
                                    }
                                }}
                            />
                        </div>
                        {renderButton({
                            name: 'status-form',
                            content: 'Отправить запрос',
                            onClick: this.getHandlerButton(),
                            withArrow: false,
                        })}
                        <div
                            className="join__blockBack"
                            onClick={() => {
                                setCurrentStep('phone');
                            }}
                        >
                            Войти с другим номером телефона
                        </div>
                    </>
                );
            },
        },
        'smz-check': {
            render() {
                const { renderButton } = this.props;

                return (
                    <>
                        <div className="join__fnsLogo _col">
                            <img
                                src={require('../../../img/fns-logo.svg').default}
                                alt=""
                                className="join__fnsLogoIcon"
                            />
                        </div>
                        <div className="join__blockTitle">Подтвердите подключение</div>
                        <div className="join__blockText _marginBottom">
                            Перейдите в «Мой налог» —<br />
                            Раздел «Прочее» — «Партнеры» —<br />
                            «Доступные» и подтвердите
                            <br />
                            подключение
                        </div>
                        {renderButton({
                            name: 'status-check',
                            content: 'Проверить подключение',
                            onClick: this.getHandlerButton(),
                            withArrow: false,
                        })}
                        <div
                            className="join__blockBack"
                            onClick={() => {
                                this.setCurrentPage({ currentPage: 'smz-form' });
                            }}
                        >
                            Вернуться назад
                        </div>
                    </>
                );
            },
        },
        'smz-numbersInfo': {
            render() {
                const { fnsPhone, savedPhone } = this.state;
                const { renderButton } = this.props;

                return (
                    <>
                        <div className="join__fnsLogo _col">
                            <img
                                src={require('../../../img/fns-logo.svg').default}
                                alt=""
                                className="join__fnsLogoIcon"
                            />
                        </div>
                        <div className="join__blockTitle">
                            Номер телефона
                            <br />
                            не совпадает
                        </div>
                        <div className="join__blockText">
                            Номер телефона <b className="_noWrap">{savedPhone}</b> при подключении
                            не&nbsp;совпадает с&nbsp;номером телефона{' '}
                            <b className="_noWrap">{fnsPhone}</b>, привязанным в&nbsp;«Мой налог»
                        </div>
                        <div className="join__blockText _marginBottom">
                            <b> Выберите подходящий сценарий:</b>
                        </div>

                        <div className="join__action _col">
                            <p className="join__actionSupport">СЦЕНАРИЙ 1</p>
                            <div className="join__actionTitle">
                                Актуальный номер —<b>{savedPhone}</b>
                            </div>
                            <div className="join__blockText _marginBottom">
                                Если ваш актуальный номер телефона{' '}
                                <b className="_noWrap _link">{savedPhone}+7-913-174-73-80</b>, вам
                                необходимо изменить номер в&nbsp;приложении «Мой налог». После этого
                                нажать на&nbsp;кнопку «Проверить ещё раз»
                            </div>
                            {renderButton({
                                name: 'status-numbersInfo-again',
                                content: 'Проверить ещё раз',
                                onClick: this.getHandlerButton(),
                                withArrow: false,
                            })}
                        </div>
                        <div className="join__action _col">
                            <p className="join__actionSupport">СЦЕНАРИЙ 2</p>
                            <div className="join__actionTitle">
                                Актуальный номер —<b>{fnsPhone}</b>
                            </div>
                            <div className="join__blockText _marginBottom">
                                Если ваш актуальный номер телефона{' '}
                                <b className="_noWrap _link">{fnsPhone}</b>, нажмите на кнопку
                                «Изменить номер». Мы&nbsp;сохраним его
                            </div>
                            {renderButton({
                                name: 'status-numbersInfo-updateNumber',
                                content: 'Изменить номер',
                                onClick: this.getHandlerButton('updateNumber'),
                                withArrow: false,
                            })}
                        </div>
                    </>
                );
            },
        },
    };

    getSmzFormFields() {
        const { currentSmzFormType } = this.state;

        return [{ key: currentSmzFormType }];
    }

    renderSmzFormField({ prop: key }) {
        const { renderField } = this.props;
        const name = `smz-${key}`;

        return <div className="join__smzFormField">{renderField({ name })}</div>;
    }

    renderStatusPage({ prop: key }) {
        const page = this.pages[key];

        return <div className="join__statusPage _col">{page?.render?.call(this)}</div>;
    }

    componentDidMount() {
        const { fnsStatus, fields } = this.props;

        if (fnsStatus === 'process') {
            this.setCurrentPage({ currentPage: 'smz-check' });
        }

        this.setState({ savedPhone: fields?.phone?.value });
    }

    render() {
        const { currentPage } = this.state;
        const { setUpdateKey, scrollToTop } = this.props;

        return (
            <div className="join__blockInner _col">
                <ListAbsoluteMain
                    className="join__statusPages"
                    items={this.getStatusPages()}
                    renderItem={this.renderStatusPage}
                    classNameItem="join__statusPage"
                    prop="key"
                    paramsParent={{ width: true }}
                    styles={['height']}
                    currentItemKey={currentPage}
                    allItems={this.getStatusPages(true)}
                    isNotParamsItem={true}
                    callback={({ isChangeLen }) => {
                        if (isChangeLen) {
                            setUpdateKey();
                            scrollToTop();
                        }
                    }}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(JoinStatus);

JoinStatus.propTypes = {
    user: PropTypes.object,
    renderButton: PropTypes.func,
    joinScript: PropTypes.object,
    currentOrganization: PropTypes.string,
    setOrganizationStatus: PropTypes.func,
    setUpdateKey: PropTypes.func,
    renderField: PropTypes.func,
    statusError: PropTypes.string,
    handlerStatusError: PropTypes.func,
    scrollToTop: PropTypes.func,
    fields: PropTypes.object,
    handlerErrors: PropTypes.func,
    getLink: PropTypes.func,
    handlerLoadingButton: PropTypes.func,
    fnsStatus: PropTypes.string,
    handlerFns: PropTypes.func,
    setCurrentStep: PropTypes.func,
};
