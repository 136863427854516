import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import Animate from '../../components/Animate.jsx';
import Avatar from '../../components/Avatar.jsx';
import Button from '../../components/Button.jsx';
import Icon from '../../components/Icon.jsx';
import Link from '../../components/Link.jsx';
import ListAbsoluteMain from '../../components/ListAbsoluteMain.jsx';
import Loader from '../../components/Loader.jsx';
import Ymaps from '../../components/Ymaps.jsx';
import Contract from '../../components/app/Contract.jsx';
import OrderStatus from '../../components/app/OrderStatus.jsx';
import CounterNotRead from '../../components/chat/CounterNotRead.jsx';

import checkAuth from '../../functions/app/checkAuth';
import getOrderTimeType from '../../functions/app/getOrderTimeType';
import { getTimeToOrder } from '../../functions/app/getTimeToOrder';
import handlerFastOrder from '../../functions/app/handlerFastOrder';
import handlerFns from '../../functions/app/handlerFns';
import handlerPopup from '../../functions/app/handlerPopup';
import handlerTax from '../../functions/app/handlerTax';
import showStartPopups from '../../functions/app/showStartPopups';
import getCurrentCorporation from '../../functions/crm/getCurrentCorporation';
import getEndText from '../../functions/getEndText';
import getExecutorMapIcon from '../../functions/getExecutorMapIcon';
import getFormatPrice from '../../functions/getFormatPrice';
import getFormatedDate from '../../functions/getFormatedDate';
import setSpacesInText from '../../functions/setSpacesInText';
import signContract from '../../functions/signContract';

import MobSlider from '../../classes/MobSlider';
import { dispatcher } from '../../redux/redux';

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentCard: 0,
        };

        this.isAcceptGetGeo = !!localStorage.getItem('isAcceptGetGeo');

        this.renderNav = this.renderNav.bind(this);
        this.mapBootstrap = this.mapBootstrap.bind(this);
        this.setGps = this.setGps.bind(this);
        this.getGps = this.getGps.bind(this);
        this.renderCard = this.renderCard.bind(this);
        this.renderPagination = this.renderPagination.bind(this);
        this.checkPagination = this.checkPagination.bind(this);
        this.touchStart = this.touchStart.bind(this);
        this.touchMove = this.touchMove.bind(this);
        this.touchEnd = this.touchEnd.bind(this);
        this.signContract = this.signContract.bind(this);

        this.parent = React.createRef();
    }

    mapInfo = {
        center: [55.879541, 37.66556],
        zoom: 16,
        controls: [],
    };

    mapBootstrap({ ymaps, map }) {
        this.setState({ ymaps, map }, () => {
            this.setGps(false);
        });
    }

    getCards() {
        const { user } = this.props;

        if (!user) {
            return [];
        }

        const { orders = [], contract, pays = [] } = user;
        const [order] = orders.filter((innerOrder) => getOrderTimeType({ order: innerOrder }));
        const corporation = getCurrentCorporation({ user });

        const cards = [{ key: 'map' }];

        if (order) {
            cards.unshift({ key: order.id, name: 'order', order });
        }

        if (user.organization === 'SMZ') {
            if (user.isJurStatusActive === true && !user.fnsTax) {
                cards.unshift({ key: 'tax', name: 'tax' });
            }
        }

        if (contract && contract?.status !== 'complete' && corporation) {
            cards.unshift({ key: `contract-${contract._id}`, name: 'contract', contract });
        } else {
            cards.unshift(...pays.map((pay) => ({ key: `pay-${pay._id}`, name: 'pay', pay })));
        }

        if (user.organization === 'SMZ') {
            if (user.isJurStatusActive !== true) {
                cards.unshift({ key: 'fns', name: 'fns' });
            }
        }

        return cards;
    }

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    async signContract() {
        const { user } = this.props;
        const { contract } = user;

        await this.handlerLoading('sign');

        try {
            await signContract({ itemsIds: [contract._id] });

            handlerPopup({
                name: 'appSignContractPopup',
                isShow: true,
                contractName: contract.name,
            });

            this.handlerLoading(null);
        } catch (error) {
            this.handlerLoading(null);
        }
    }

    renderOrder({ order }) {
        return (
            <div className="index__order _row">
                <div className="index__orderBlock _col _map">
                    <img
                        src={require('../../img/app/map.jpg')}
                        alt=""
                        className="index__orderBack"
                    />
                    <Link
                        className="index__orderBtn _col"
                        href="order-details-inner"
                        keyUniq={order.id}
                    >
                        <i className="index__orderBtnIcon">
                            <Icon name="app-touch" />
                        </i>
                        <div className="index__orderBtnTitle">
                            Управлять
                            <br />
                            заказом
                        </div>
                    </Link>
                </div>
                <div className="index__orderBlock _col _content">
                    <div className="index__orderInfo _col">
                        <div className="index__orderStatus">
                            <OrderStatus name={getOrderTimeType({ order })}>
                                {getOrderTimeType({ order }) === 'wait'
                                    ? `через ${getTimeToOrder({
                                          order,
                                          isStart: true,
                                          isShort: true,
                                      })}`
                                    : null}
                            </OrderStatus>
                        </div>
                        <div className="index__orderTitle">
                            {getFormatedDate({
                                date: new Date(order.dateOfOrder),
                                isShortYear: true,
                            })}{' '}
                            в{' '}
                            {getFormatedDate({
                                date: new Date(order.dateOfOrder),
                                type: 'time',
                            })}
                        </div>
                        <div className="index__orderAddresses">
                            <div className="index__orderAddress">
                                <span>От:</span> {order.route[0].address}
                            </div>
                            {order.route.length > 1 && (
                                <div className="index__orderAddress">
                                    <span>До:</span> {order.route[order.route.length - 1].address}
                                </div>
                            )}
                        </div>
                        {order.route.length > 2 && (
                            <div className="index__orderAddressesMore">
                                +{order.route.length - 2}{' '}
                                {getEndText(order.route.length - 2, ['адрес', 'адреса', 'адресов'])}
                            </div>
                        )}

                        <p className="index__orderNumber">ID{order.number}</p>
                    </div>
                </div>
            </div>
        );
    }

    renderMap() {
        const { ymaps, map, isReadyMap } = this.state;
        const { isAcceptGetGeo, appCoords } = this.props;
        const isGetAppCoords = (appCoords && ymaps && map) || this.isAcceptGetGeo;

        return (
            <div className="index__map">
                <Animate
                    className="index__mapInfo _col"
                    isShow={!isAcceptGetGeo || !isGetAppCoords}
                >
                    <div className="index__mapInfoTitle">Где я сейчас нахожусь?</div>
                    <p className="index__mapInfoDescription">
                        Нажмите кнопку ниже, чтобы определить ваше местоположение
                    </p>
                    <div className="index__mapInfoButton">
                        <Button
                            onClick={() => {
                                this.setGps();
                            }}
                            showLoader={isAcceptGetGeo && !isGetAppCoords}
                        >
                            Определить местоположение
                        </Button>
                    </div>
                </Animate>
                <Animate
                    className="index__mapBox"
                    isShow={isAcceptGetGeo && (appCoords || this.isAcceptGetGeo)}
                >
                    <div className="index__mapInner">
                        {isReadyMap && <Ymaps {...this.mapInfo} callback={this.mapBootstrap} />}
                    </div>
                    <div className="index__mapGeo _col" onClick={this.setGps}>
                        <i className="index__mapGeoIcon">
                            <Icon name="app-geo" />
                        </i>
                    </div>
                </Animate>
            </div>
        );
    }

    renderContract({ currentCorporation, contract }) {
        const { loadingKey } = this.state;

        return (
            <div className="index__contract _col">
                <div className="index__contractHead _row">
                    <div className="index__contractHeadLogo">
                        <Avatar
                            src={
                                currentCorporation.logo.path &&
                                `${process.env.REACT_APP_STATIC}/corporations/${currentCorporation?.logo.path}`
                            }
                            holder={{
                                text: currentCorporation.name?.[0]?.toUpperCase(),
                            }}
                            className="index__contractHeadLogoInner _col"
                            withText={true}
                            iconType={currentCorporation.logo.type}
                        />
                    </div>
                    <div className="index__contractHeadContent">
                        <div className="index__contractHeadTitle">Подпишите договор:</div>
                        <div className="index__contractHeadName">
                            Компания «{currentCorporation.name}»
                        </div>
                    </div>
                </div>
                <div className="index__contractInfo">
                    <Contract contract={contract} />
                </div>
                <div className="index__contractButton">
                    <Button
                        className=""
                        onClick={this.signContract}
                        showLoader={loadingKey === 'sign'}
                        icon={{ type: 'end', name: 'arrow-next-2' }}
                    >
                        Подписать договор
                    </Button>
                </div>
            </div>
        );
    }

    signPay({ id }) {
        handlerPopup({ name: 'appSignPayPopup', isShow: true, payId: id });
    }

    renderPay({ currentCorporation, pay }) {
        const title = `Подпишите акт №${pay.number}: ${pay.name}`;

        return (
            <div className="index__pay _col">
                <div
                    className="index__payTitle"
                    dangerouslySetInnerHTML={{ __html: setSpacesInText(title) }}
                ></div>
                <div className="index__payInfo">
                    <span>Корпорация:</span> «{currentCorporation.name}»
                </div>
                <div className="index__payInfo _last">
                    <span>Сумма:</span> {getFormatPrice(pay.amount)} ₽
                </div>
                <div className="index__payButton">
                    <Button
                        onClick={() => this.signPay({ id: pay._id })}
                        icon={{ type: 'end', name: 'arrow-next-2' }}
                    >
                        Подписать акт
                    </Button>
                </div>
            </div>
        );
    }

    renderTax() {
        const { loadingKey } = this.state;

        return (
            <div className="index__tax">
                <i
                    className="index__taxInfo _click"
                    onClick={() => {
                        handlerPopup({ name: 'appTaxAboutPopup', isShow: true });
                    }}
                >
                    <Icon name="info" />
                </i>
                <div className="index__taxHead _row">
                    <div className="index__taxHeadIcon _col">
                        <i className="index__taxHeadIconItem">
                            <Icon name="app-settings-fns-tax" />
                        </i>
                    </div>
                    <div className="index__taxHeadTitle">
                        Подключите
                        <br />
                        налоговую копилку
                    </div>
                </div>
                <div className="index__taxButton _add">
                    <Button
                        onClick={handlerTax.bind(this, 'add', true)}
                        showLoader={loadingKey === 'add'}
                    >
                        Подключить копилку
                    </Button>
                </div>
                <div className="index__taxButton">
                    <Button
                        className="_alertLight _notShadow"
                        onClick={handlerTax.bind(this, 'cancel')}
                        showLoader={loadingKey === 'cancel'}
                    >
                        Отказаться
                    </Button>
                </div>
            </div>
        );
    }

    renderFns() {
        const { loadingKey } = this.state;

        return (
            <div className="index__fns">
                <div className="index__fnsHead _row">
                    <img
                        src={require('../../img/fns-logo.svg').default}
                        alt=""
                        className="index__fnsHeadIcon"
                    />
                    <div className="index__fnsHeadTitle">
                        Подключите
                        <br />
                        Мой Налог
                    </div>
                </div>
                <div className="index__fnsDescription">
                    Чтобы получать выплаты, необходимо подключить нашего партнера
                </div>
                <div className="index__fnsButton _add">
                    <Button
                        onClick={handlerFns.bind(this, { isAdd: true, isChangePage: true })}
                        showLoader={loadingKey === 'saveFns'}
                    >
                        Подключить
                    </Button>
                </div>
            </div>
        );
    }

    renderCard({ item }) {
        const { user } = this.props;
        const currentCorporation = getCurrentCorporation({ user });

        return (
            <div className="index__infoCard">
                {item.name === 'fns' && <>{this.renderFns({})}</>}
                {item.name === 'tax' && <>{this.renderTax({})}</>}
                {item.name === 'order' && <>{this.renderOrder({ order: item.order })}</>}
                {item.key === 'map' && <>{this.renderMap()}</>}
                {item.name === 'contract' && (
                    <>{this.renderContract({ currentCorporation, contract: item.contract })}</>
                )}
                {item.name === 'pay' && (
                    <>{this.renderPay({ currentCorporation, pay: item.pay })}</>
                )}
            </div>
        );
    }

    navsOrder = ['balance', 'pays', 'chat', 'settings', 'notifications'];

    navs = {
        orders: {
            icon: 'app-main-orders',
            title: 'Мои заказы',
            href: 'orders',
        },
        balance: {
            icon: 'app-main-balance',
            title: 'Баланс',
            href: 'balance',
        },
        pays: {
            icon: 'app-main-pays',
            title: 'Мои акты',
            href: 'pays',
        },
        chat: {
            icon: 'app-main-chat',
            title: 'Поддержка',
            description: 'Напишите в чат',
            href: 'chat',
        },
        settings: {
            icon: 'app-main-settings',
            title: 'Настройки',
            description: 'Для профиля',
            href: 'settings',
        },
        notifications: {
            icon: 'app-main-notification',
            title: 'Уведомления',
            href: 'notifications',
        },
        corporations: {
            icon: 'app-main-corporations',
            title: 'Корпорации',
            href: 'corporations',
        },
    };

    renderNav(name) {
        const { user } = this.props;
        const nav = this.navs[name];
        const { title, icon, href } = nav;
        let { description } = nav;

        if (name === 'orders') {
            description = `Доступно: ${user?.marketCounter}`;
        }

        if (name === 'balance') {
            description = `${getFormatPrice(user?.balance)} ₽`;
        }

        if (name === 'pays') {
            description = `За всё время: ${user?.paysCounter}`;
        }

        if (name === 'notifications') {
            description = `Новых: ${user?.notificationsCounter || 0}`;
        }

        if (name === 'markets') {
            description = `Доступно: ${user?.marketCounter || 0}`;
        }

        if (name === 'corporations') {
            description = `Подключено: ${
                user?.corporations.filter((item) => item.id !== 'admin').length || 0
            }`;
        }

        return (
            <Link className={`index__navBlock _col _${name}`} key={name} href={href}>
                <i className="index__navBlockIcon">
                    {name === 'chat' && (
                        <div className="index__navBlockChatCounter">
                            <CounterNotRead type="all" />
                        </div>
                    )}
                    <Icon name={icon} />
                </i>
                <div className="index__navBlockTitle">{title}</div>
                <div className="index__navBlockDescription">{description}</div>
            </Link>
        );
    }

    getType() {
        const { user } = this.props;

        return user?.typeText;
    }

    getCar() {
        const { user } = this.props;

        if (user?.car) {
            const car = user.infoCars.find((innerCar) => innerCar._id === user.car);

            return `${car.mark} ${car.model}`;
        }

        return '–';
    }

    setGps(isGet = true) {
        const { ymaps, map } = this.state;
        const { appCoords } = this.props;

        if (!appCoords) {
            if (isGet) {
                dispatcher({ type: 'isAcceptGetGeo', data: true });
            }
        } else if (ymaps && map) {
            this.getGps();

            map.setCenter(appCoords, 17, {
                checkZoomRange: true,
            });
        }
    }

    getGps() {
        const { ymaps, map } = this.state;
        const { user, appCoords } = this.props;

        if (ymaps && map && user) {
            const myPlacemark = new ymaps.Placemark(
                appCoords,
                {},
                {
                    iconLayout: 'default#image',
                    iconImageHref: require(`../../img/${getExecutorMapIcon({ type: user.type })}`),
                    iconImageSize: [38, 38],
                    iconImageOffset: [-16, -16],
                },
            );

            map.geoObjects.removeAll();

            map.geoObjects.add(myPlacemark);

            if (!this.isGeoInit) {
                this.isGeoInit = true;

                map.setCenter(appCoords, 15, {
                    checkZoomRange: true,
                });
            }
        }
    }

    checkPagination({ detail: { id, current } }) {
        if (id === 'order') {
            this.setState({ currentCard: current });
        }
    }

    getPaginations() {
        return this.getCards().length > 1 ? this.getCards() : [];
    }

    renderPagination({ key }) {
        const { currentCard } = this.state;

        return (
            <div className={`pagination__item ${currentCard === key ? '_current' : ''}`}>
                <div className="pagination__itemInner"></div>
            </div>
        );
    }

    sliderTimerId;

    sliderInit = false;

    handlerSlider() {
        const cards = this.getCards();
        const slider = this.parent.current.querySelector('.index__info');

        if (cards.length > 1) {
            if (!this.sliderInit) {
                if (this.sliderTimerId) {
                    clearTimeout(this.sliderTimerId);
                    document.removeEventListener('change-slider', this.checkPagination);
                }

                this.setState({ currentCard: 0 });

                this.sliderInit = true;

                this.sliderTimerId = setTimeout(() => {
                    this.slider = new MobSlider({
                        id: 'order',
                        slider,
                        lay: slider.querySelector('.index__infoCards'),
                        items: slider.querySelectorAll('.index__infoCard'),
                        infinity: false,
                        current: 0,
                        // withResize: true,
                    });

                    document.addEventListener('change-slider', this.checkPagination);
                }, 300);
            }

            if (this.slider) {
                let newCurrent = this.state.currentCard;

                if (!cards[this.state.currentCard]) {
                    newCurrent = cards.length - 1;

                    this.setState({ currentCard: newCurrent });
                }

                this.slider.setNeedItem(newCurrent);

                this.sliderTimerId = setTimeout(() => {
                    this.slider.destroy();

                    this.slider = new MobSlider({
                        id: 'order',
                        slider,
                        lay: slider.querySelector('.index__infoCards'),
                        items: slider.querySelectorAll('.index__infoCard'),
                        infinity: false,
                        current: newCurrent,
                    });

                    document.addEventListener('change-slider', this.checkPagination);
                }, 300);
            }
        } else if (this.sliderInit) {
            if (this.slider) {
                this.slider.destroy();
            }

            this.sliderInit = false;

            document.removeEventListener('change-slider', this.checkPagination);
        }
    }

    orderTimerId;

    checkOrderTimer() {
        if (this.getCards().find((item) => item.name === 'order')) {
            clearInterval(this.orderTimerId);

            if (!this.isInitOrder) {
                this.setState({ currentCard: 0 });

                this.isInitOrder = true;
            }

            this.orderTimerId = setInterval(() => {
                this.setState({ updateTimerKey: new Date().getTime() });
            }, 15_000);
        } else if (this.orderTimerId) {
            clearInterval(this.orderTimerId);

            if (!this.isInitOrder) {
                this.setState({ currentCard: 0 });

                this.isInitOrder = false;
            }
        }
    }

    startY = 0;

    moveY = 0;

    isTouchProccess = false;

    deltaY = null;

    getInner() {
        return this.parent.current.querySelector('.index__inner');
    }

    getLoader() {
        return this.parent.current.querySelector('.index__loader');
    }

    getScroll() {
        const innerScroll = this.parent.current;

        if (innerScroll) {
            return innerScroll.scrollTop;
        }

        return 0;
    }

    moveInner() {
        const inner = this.getInner();
        const loader = this.getLoader();
        const loaderInner = loader.querySelector('.index__loaderInner');

        if (this.moveY < 0) {
            this.moveY = 0;
        }

        const opacity = Math.abs(this.moveY) / 170;
        let scale = Math.abs(this.moveY) / 200;

        if (scale > 1) {
            scale = 1;
        }

        loader.style.opacity = opacity - 0.3;

        loaderInner.style.transform = `scale(${scale})`;

        inner.style.transform = `translate(0,${this.moveY / 3}px)`;
    }

    touchStart(e) {
        if (!this.isReload && !this.isTouchProccess && this.getScroll() < 3) {
            this.isTouchProccess = true;

            this.startY = e.changedTouches[0].pageY;
        }
    }

    touchMove(e) {
        const index = this.parent.current;

        if (!this.isReload && this.isTouchProccess) {
            this.moveY = e.changedTouches[0].pageY - this.startY;

            if (Math.abs(this.moveY) > 3 && this.deltaY === null) {
                this.deltaY = this.moveY < 0 ? -1 : 1;
            }

            if (this.getScroll() < 3 && this.deltaY === 1) {
                e.preventDefault();

                index.classList.add('_disabled');

                this.moveInner();
            }
        }
    }

    touchEnd() {
        const index = this.parent.current;
        const inner = this.getInner();

        if (!this.isReload && this.isTouchProccess) {
            index.classList.remove('_disabled');
            this.startY = 0;

            if (this.moveY > 200) {
                this.moveY = 200;
                this.isReload = true;
                this.parent.current.style.overflow = 'hidden';
                inner.style.pointerEvents = 'none';

                localStorage.setItem('isAppReload', true);

                setTimeout(() => {
                    this.moveY = 0;

                    this.setState({ isReload: true });

                    this.moveInner();
                }, 700);

                setTimeout(() => {
                    window.location.reload(true);
                }, 1_000);
            } else {
                this.moveY = 0;
                this.isTouchProccess = false;
                this.deltaY = null;
            }

            this.moveInner();
        }
    }

    componentDidMount() {
        const { user } = this.props;

        if (user) {
            showStartPopups();

            checkAuth(false);

            this.checkOrderTimer();

            handlerFastOrder({ user });

            document.addEventListener('getGps', this.getGps);
        }

        this.parent.current.addEventListener('touchstart', this.touchStart, { passive: false });

        document.addEventListener('touchmove', this.touchMove, { passive: false });
        document.addEventListener('touchend', this.touchEnd);
    }

    componentDidUpdate() {
        this.checkOrderTimer();
    }

    componentWillUnmount() {
        document.removeEventListener('getGps', this.getGps);

        clearInterval(this.orderTimerId);

        this.parent.current.removeEventListener('touchstart', this.touchStart);

        document.removeEventListener('touchmove', this.touchMove);
        document.removeEventListener('touchend', this.touchEnd);
    }

    render() {
        const { isReload } = this.state;
        const { user } = this.props;
        const type = this.getType();
        const paginations = this.getPaginations();
        const currentCorporation = getCurrentCorporation({ user });

        return (
            <div ref={this.parent} className={`index ${isReload ? '_reload' : ''}`}>
                <div className="index__loader">
                    <div className="index__loaderInner">
                        <Loader className="_main" />
                    </div>
                </div>
                <div className="index__inner">
                    <div className="index__head _row">
                        <div className="index__headAvatar">
                            <Avatar type="executor" holder={user} />
                        </div>
                        <div className="index__headContent">
                            <p className="index__headName">
                                {user?.secondName} {user?.firstName}
                            </p>
                            <div className="index__headParams _row">
                                <div className="index__headParam _name">
                                    <i className="index__headParamIcon">
                                        <Icon name="app-info-user" />
                                    </i>
                                    {type}
                                </div>
                                <div className="index__headParam">
                                    <i className="index__headParamIcon">
                                        <Icon name="app-info-corporation" />
                                    </i>
                                    {currentCorporation?.name || '–'}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="index__info">
                        <ListAbsoluteMain
                            className="index__infoCards"
                            items={this.getCards()}
                            renderItem={this.renderCard}
                            classNameItem="index__infoCard"
                            prop="key"
                            styles={['width', 'height']}
                            callback={({ type: cbType, isChangeLen }) => {
                                if (cbType === 'parent' || isChangeLen) {
                                    this.handlerSlider();

                                    if (!this.state.isReadyMap) {
                                        setTimeout(() => {
                                            this.setState({ isReadyMap: true });
                                        }, 10);
                                    }
                                }
                            }}
                            minHeight={184}
                        />
                        <div
                            className={`index__infoPagination _row ${
                                paginations.length === 0 ? '_empty' : ''
                            }`}
                        >
                            <ListAbsoluteMain
                                className="pagination _dynamic"
                                items={paginations}
                                renderItem={this.renderPagination}
                                classNameItem="pagination__item"
                                prop="key"
                                styles={['width']}
                            />
                        </div>
                    </div>
                    <div className="index__nav _row">{this.navsOrder.map(this.renderNav)}</div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        appCoords: state.appCoords,
        isAcceptGetGeo: state.isAcceptGetGeo,
    };
}

export default connect(mapStateToProps)(Index);

Index.propTypes = {
    user: PropTypes.object,
    appCoords: PropTypes.array,
    isAcceptGetGeo: PropTypes.bool,
};
