import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import handlerPopup from '../../../functions/app/handlerPopup';

class JoinPhone extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { renderField, renderButton } = this.props;

        return (
            <div className="join__blockInner _col">
                <div className="join__blockTitle">
                    Введите номер
                    <br />
                    вашего телефона
                </div>
                <div className="join__blockText">
                    {/* Вам поступит звонок на указанный номер&nbsp;телефона */}
                    Мы отправим на него 5-значный код для авторизации
                </div>
                <div className="join__blockFields">{renderField({ name: 'phone' })}</div>
                {renderButton({ name: 'checkPhone', content: 'Продолжить' })}
                <p className="join__policy">
                    Нажимая на кнопку «Продолжить» я подтверждаю,
                    <br />
                    что&nbsp;ознакомлен и согласен с&nbsp;
                    <span
                        className="_click"
                        onClick={() => {
                            handlerPopup({
                                name: 'appInfoPopup',
                                isShow: true,
                                popupName: 'user-agreement',
                            });
                        }}
                    >
                        пользовательским соглашением
                    </span>{' '}
                    и&nbsp;
                    <span
                        onClick={() => {
                            handlerPopup({
                                name: 'appInfoPopup',
                                isShow: true,
                                popupName: 'policy',
                            });
                        }}
                    >
                        политикой обработки и защиты персональных данных
                    </span>
                </p>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(JoinPhone);

JoinPhone.propTypes = {
    user: PropTypes.object,
    renderField: PropTypes.func,
    renderButton: PropTypes.func,
    corporationName: PropTypes.string,
    link: PropTypes.string,
};
