import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import Animate from '../../../components/Animate.jsx';
import CodeBox from '../../../components/CodeBox.jsx';
import Loader from '../../../components/Loader.jsx';

class JoinCode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { setCurrentStep, checkCode, handlerButton, loadingButton } = this.props;

        return (
            <div className="join__blockInner _col">
                <div className="join__blockTitle">
                    Введите код
                    <br />
                    подтверждения
                </div>
                <div className="join__blockText">
                    {/* Необходимо ввести <b>последние 4 цифры</b> номера телефона, с которого вам
                    поступил звонок */}
                    Необходимо ввести 5-значный код, который поступил вам в смс
                </div>
                <div className="join__blockCode">
                    <CodeBox
                        isNumber={true}
                        className="_join"
                        inputs={[1, 2, 3, 4, 5]}
                        callback={checkCode}
                    />
                </div>
                <div
                    className="join__blockBack"
                    onClick={() => {
                        setCurrentStep('phone');
                    }}
                >
                    Ввести другой номер
                </div>
                <div
                    className="join__blockBack"
                    onClick={() => {
                        handlerButton({ name: 'checkPhone', isForce: true });
                    }}
                >
                    <Animate
                        className="join__blockBackLoader _loader"
                        isShow={loadingButton === 'checkPhone'}
                    >
                        <div className="join__blockBackLoaderItem _loaderItem">
                            <Loader className="_main" />
                        </div>
                    </Animate>
                    Запросить смс повторно
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(JoinCode);

JoinCode.propTypes = {
    user: PropTypes.object,
    setCurrentStep: PropTypes.func,
    checkCode: PropTypes.func,
    handlerButton: PropTypes.func,
    loadingButton: PropTypes.bool,
};
